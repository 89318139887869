<template>
  <div class="container-fluid">
    <div class="col-12 text-center mb-5 mt-5">
      <h2>{{ $t("CASH_REGISTER_READINGS.AMOUNT_ACCOUNT") }}</h2>
    </div>
    <cash-register-reading-payment-items
      v-if="cashRegisterReading.id"
      @onExpenseChanged="expenseUpdated"
      :filterOrganization="cashRegisterReading.organization?.id"
      :cashRegisterReading="cashRegisterReading"
      :destinationWarehouseId="cashRegisterReading.cashRegister.warehouse?.id"
      :key="renderKey"
    />

    <div class="col-12 text-center mb-5 mt-5">
      <h2>{{ $t("CASH_REGISTER_READINGS.CASHOUT") }}</h2>
    </div>
    <cash-register-reading-expense-items
      v-if="cashRegisterReading.id"
      @onExpenseChanged="expenseUpdated"
      :filterOrganization="cashRegisterReading.organization?.id"
      :cashRegisterReading="cashRegisterReading.id"
      :destinationWarehouseId="cashRegisterReading.cashRegister.warehouse?.id"
      :key="renderKeySecond"
      :addLineText="
        $t('CASH_REGISTER_READING_EXPENSE_ITEMS.ADD_CASH_REGISTER_READING_EXPENSE_ITEM')
      "
    />

    <!-- <div class="col-12 text-center mb-5 mt-5">
      <h2>{{ $t("CASH_REGISTER_READINGS.SUBMITION") }}</h2>
    </div>

    <cash-register-reading-invoice-items
      v-if="cashRegisterReading.id"
      @onInvoiceChanged="invoiceUpdated"
      :filterOrganization="cashRegisterReading.organization?.id"
      :cashRegisterReading="cashRegisterReading.id"
      :destinationWarehouseId="cashRegisterReading.cashRegister.warehouse?.id"
      :addLineText="
        $t('CASH_REGISTER_READING_INVOICE_ITEMS.ADD_CASH_REGISTER_READING_INVOICE_ITEM')
      "
    /> -->

    <div class="col-12 text-center mb-5 mt-5">
      <h2>{{ $t("CASH_REGISTER_READINGS.EXPECTED_ACCOUNT") }}</h2>
    </div>

    <table class="table table-bordered">
      <tbody>
        <tr>
          <td>{{ $t("CASH_REGISTER_READINGS.CASHIN") }}</td>
          <td>
            <base-input
              @wheel.prevent
              :disabled="loading"
              v-model="localCashRegisterReading.amount_z"
              @change="updateRegisterReadingDebounced"
            />
            <validation-error :errors="apiValidationErrors.amount_z" />
          </td>
        </tr>
        <tr>
          <td>{{ $t("CASH_REGISTER_READINGS.REGISTER_ERRORS") }}</td>
          <td>
            <base-input
              @wheel.prevent
              :disabled="loading"
              v-model="localCashRegisterReading.register_errors"
              @change="updateRegisterReadingDebounced"
            />
            <validation-error :errors="apiValidationErrors.register_errors" />
          </td>
        </tr>
        <tr>
          <td>{{ $t("CASH_REGISTER_READINGS.CASHOUT") }}</td>
          <td>{{ $formatCurrency(cashRegisterReading.expense_amount) }}</td>
        </tr>
        <tr>
          <td>
            {{ $t("CASH_REGISTER_READINGS.TOTAL_OF_SALES") }}
          </td>
          <td>{{ $formatCurrency(cashRegisterReading.total_cash_in) }}</td>
        </tr>
        <tr>
          <td>{{ $t("CASH_REGISTER_READINGS.CASH_BALANCE") }}</td>
          <td>{{ $formatCurrency(cashRegisterReading.total_amount) }}</td>
        </tr>
        <tr>
          <td>{{ $t("CASH_REGISTER_READINGS.AMOUNT_GAP") }}</td>
          <td>{{ $formatCurrency(cashRegisterReading.amount_gap) }}</td>
        </tr>
        <!-- <tr>
          <td>{{ $t("CASH_REGISTER_READINGS.SUBMITION") }}</td>
          <td>{{ $formatCurrency(cashRegisterReading.external_invoice_amount) }}</td>
        </tr> -->
      </tbody>
    </table>
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
import LogListTable from "@/views/Pages/DefaultModule/LogManagement/partials/LogListTable.vue";
import CashRegisterReadingPaymentItems from "@/components/CashRegisterReadingPaymentItem/CashRegisterReadingPaymentItems.vue";
import CashRegisterReadingExpenseItems from "@/components/CashRegisterReadingExpenseItem/CashRegisterReadingExpenseItems.vue";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import formMixin from "@/mixins/form-mixin";
import CashRegisterReadingInvoiceItems from "@/components/CashRegisterReadingInvoiceItem/CashRegisterReadingInvoiceItems.vue";

export default {
  name: "cash-register-reading-view-payment-items",

  components: {
    LogListTable,
    CashRegisterReadingPaymentItems,
    CashRegisterReadingExpenseItems,
    BaseInput,
    ValidationError,
    CashRegisterReadingInvoiceItems,
  },

  props: {
    cashRegisterReading: {
      type: Object,
      default: null,
      description: "cash register reading",
    },
  },

  mixins: [formMixin],

  data() {
    return {
      loading: false,
      renderKey: 0,
      renderKeySecond: 2,
      localCashRegisterReading: cloneDeep(this.cashRegisterReading),
    };
  },

  computed: {},

  methods: {
    updateRegisterReadingDebounced: _.debounce(function () {
      this.handleSubmit();
    }, 800),

    expenseUpdated() {
      this.$emit("cashRegisterReadingUpdated");
    },
    invoiceUpdated() {
      this.$emit("cashRegisterReadingUpdated");
    },
    onFormChanged() {
      this.$emit("formChanged");
    },

    async handleSubmit() {
      this.loading = true;
      try {
        const cashRegisterReadingData = cloneDeep(this.localCashRegisterReading);
        if (cashRegisterReadingData.id) {
          console.log(cashRegisterReadingData);
          await this.$store.dispatch(
            "cashRegisterReadings/update",
            cashRegisterReadingData
          );
        }
        this.$notify({
          type: "success",
          message: this.$t("CASH_REGISTER_READINGS.CASH_REGISTER_READING_UPDATED"),
        });
        this.$emit("cashRegisterReadingUpdated");
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },

  mounted() {},

  watch: {
    cashRegisterReading: {
      deep: true,
      immediate: true,
      handler(newValue) {
        this.localCashRegisterReading = cloneDeep(newValue);
      },
    },
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    user(user) {},
  },
};
</script>
<style scoped>
.expense-list {
  list-style: none;
  padding-left: 1.5rem;
}

.expense-list li {
  position: relative;
  margin-bottom: 0.5rem;
}

.expense-list li::before {
  content: "•";
  position: absolute;
  left: -1rem;
  color: #000;
}
</style>

export default {
  CASH_REGISTER_READINGS_LIST: "Liste des lectures de caisse",
  ADD_CASH_REGISTER_READING: "Ajouter une lecture de caisse",
  CASH_REGISTER_READING_ADDED: "Lecture de caisse ajoutée",
  EDIT_CASH_REGISTER_READING: "Modifier la lecture de caisse",
  CASH_REGISTER_READING_UPDATED: "lecture de caisse mise à jour",
  DELETE_THIS_CASH_REGISTER_READING: "Supprimer cette lecture de caisse ?",
  CASH_REGISTER_READING_DELETED: "lecture de caisse supprimée",
  STARTS_AT: "Début",
  ENDS_AT: "Fin",
  CREATED_BY: "Crée par",
  UPDATED_BY: "Modifieé par",
  UPDATED_BY: "Modifieé par",
  TOTAL_AMOUNT: "Montant total",
  TITLE: "Titre",
  CASH_REGISTER_CATEGORY_PEDAGO: "Pédagogie",
  EVENT_CATEGORY_THEMATIC_MEAL: "Repas Thématique",
  EVENT_CATEGORY_TASTING: "Dégustation",
  EVENT_CATEGORY_PROMOTION: "Promotion",
  EVENT_CATEGORY_EVENT: "Événement",
  EVENT_CATEGORY_INTERNAL_JOURNAL: "Journal Interne",
  EVENT_CATEGORY_CLIENT_JOURNAL: "Journal Client",
  EVENT_CATEGORY_SURVEY: "Sondage",
  EVENT_CATEGORY_MONTHLY_INSPIRATION: "Inspiration du Mois",
  EVENT_CATEGORY_OPEN_HOUSE: "Porte-Ouverte",
  SHOW_MY_EVENTS: "Voir les caisses qui me concernent",
  SHOW_CASH_REGISTER: "Voir la caisse",
  SHOW_ALL: "Voir toutes les caisses",
  EXPENSE_LINE: "Ligne dépense",
  TOTAL_EXPENSES: "Total des dépenses",
  TOTAL_SALES: "Total des ventes",
  CASH_BALANCE: "Ventes nettes",
  CASH: "Interac débit du terminal",
  CREDIT_CARD: "Carte de crédit",
  DEBIT_CARD: "Carte de débit",
  PREPAIED_CARD: "Carte prépayée",
  OTHER: "Autre",
  TOTAL_CASHIN: "Total Cashin",
  TOTAL_CASHOUT: "Total Cashout",
  CANCEL_CASH_REGISTER_READING_QUESTION: "Annuler la lecture de caisse ?",
  VALIDATE_CASH_REGISTER_READING_QUESTION: "Valider la lecture de caisse ?",
  STATUS_DRAFT: "Brouillon",
  STATUS_CANCELED: "Annulée",
  STATUS_VALIDATED: "Validée",
  REGISTER_ERRORS: "Erreur de poinçon",
  CASHIN: "Lecture de caisse (Z ou Net1 ou vente brut)",
  CASHOUT: "Facture payée (achat extérieur)",
  NUMBER_Z: "Numéro Z",
  COINS: "Monnaie",
  AMOUNT_ACCOUNT: "Argent comptant",
  NET_BRUT: "Lecture de caisse (Z ou Net1 ou vente brut)",
  TOTAL_AMOUNT_ACCOUNT: "Total Argent comptant",
  TOTAL_PAYMENT_METHODS: "Total mode de paiement",
  PAYMENT_METHODS: "Mode de paiement",
  SUBMITION: "Soumissions",
  EXPECTED_ACCOUNT: "COMPTE À RECEVOIR",
  AMOUNT_GAP: "Ecart avec le total des ventes",
  TOTAL_OF_SALES: "Total des ventes",
  GAPS_WHEN_VALIDATE: "Il y a un écart de {gap} avec le total des ventes, voulez vous valider la lecture de caisse ?",
  GAPS_WHEN_CANCEL: "Il y a un écart de {gap} avec le total des ventes, voulez vous annuler la lecture de caisse ?"
};

<template>
  <el-select
    v-model="establishmentModel"
    @change="establishmentChanged"
    autocomplete="new-password"
    :placeholder="$t('COMMON.ESTABLISHMENT')"
    :filterable="filterable"
    :multiple="multiple"
    :disabled="disabled"
    remote
    :remote-method="getEstablishments"
    :loading="loading"
  >
    <el-option v-if="showAll" :value="null" :label="$t('COMMON.ALL_ESTABLISHMENT')">
    </el-option>
    <el-option v-if="allowNone" :value="null" :label="$t('COMMON.NONE')"> </el-option>
    <el-option
      v-for="establishment in establishments"
      :key="establishment.id"
      :value="establishment.id"
      :label="`${establishment.company_name} - ${establishment.email}`"
    >
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";

export default {
  name: "establishment-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    showAll: {
      type: Boolean,
      default: true,
      description: "Show the option all",
    },
    allowNone: {
      type: Boolean,
      default: false,
      description: "Show the option none",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    multiple: {
      type: Boolean,
      default: false,
      description: "Can filter",
    },
    establishment: {
      type: String,
      default: null,
      description: "Establishment id",
    },
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    filterServiceCenter: {
      type: String,
      default: null,
      description: "Service center id",
    },
  },

  data() {
    return {
      establishmentModel: this.establishment,
      establishments: {},
    };
  },

  setup() {},

  created() {
    this.getEstablishments(null, this.establishment);
  },

  methods: {
    async getEstablishments(query = null, id = null) {
      try {
        this.loading = true;
        let params = {
          sort: "company_name",
          filter: {
            ...(query ? { search: query } : {}),
            //   ...(id ? { id: id } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.filterOrganization,
            },
          };
        }

        if (this.filterServiceCenter) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              serviceCenter: this.filterServiceCenter,
            },
          };
        }

        await this.$store.dispatch("establishments/list", params);
        const establishmentsArr = await this.$store.getters["establishments/list"];
        this.establishments = {};
        establishmentsArr.forEach((establishment) => {
          this.establishments[establishment.id] = establishment;
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    establishmentChanged(establishment) {
      const establishmentSelected = Object.values(this.establishments).find(
        (item) => item.id === establishment
      );
      this.$emit("establishmentChanged", establishment, establishmentSelected);
    },
  },

  watch: {
    establishment(establishment) {
      if (establishment) {
        this.establishmentModel = establishment;
      } else {
        this.establishmentModel = null;
      }
      this.getEstablishments(null, this.establishment);
    },
    filterOrganization(filterOrganization) {
      this.getEstablishments();
    },
    // filterServiceCenter(filterServiceCenter) {
    //   this.getEstablishments(null, filterServiceCenter);
    // },

    filterServiceCenter: {
      handler: function (establishment) {
        this.getEstablishments();
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

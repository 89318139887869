var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('table',{staticClass:"table table-bordered"},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("COMMON.BILL_COUNT")))]),_c('th',[_vm._v(_vm._s(_vm.$t("COMMON.BILL_VALUE")))]),_c('th',[_vm._v(_vm._s(_vm.$t("COMMON.TOTAL")))])])]),_c('tbody',[_c('tr',[_c('td',[_c('base-input',{attrs:{"disable":_vm.loading,"input-classes":"form-control-alternative","type":"number"},on:{"wheel":function($event){$event.preventDefault();},"change":function($event){return _vm.updatePaymentItemDebounced(
                'bill_five',
                _vm.cashRegisterReadingPaymentItem.bill_five
              )}},model:{value:(_vm.cashRegisterReadingPaymentItem.bill_five),callback:function ($$v) {_vm.$set(_vm.cashRegisterReadingPaymentItem, "bill_five", $$v)},expression:"cashRegisterReadingPaymentItem.bill_five"}})],1),_c('td',[_vm._v("5")]),_c('td',[_vm._v(_vm._s(5 * _vm.cashRegisterReadingPaymentItem.bill_five))])]),_c('tr',[_c('td',[_c('base-input',{attrs:{"disable":_vm.loading,"input-classes":"form-control-alternative","type":"number"},on:{"wheel":function($event){$event.preventDefault();},"change":function($event){return _vm.updatePaymentItemDebounced(
                'bill_ten',
                _vm.cashRegisterReadingPaymentItem.bill_ten
              )}},model:{value:(_vm.cashRegisterReadingPaymentItem.bill_ten),callback:function ($$v) {_vm.$set(_vm.cashRegisterReadingPaymentItem, "bill_ten", $$v)},expression:"cashRegisterReadingPaymentItem.bill_ten"}})],1),_c('td',[_vm._v("10")]),_c('td',[_vm._v(_vm._s(10 * _vm.cashRegisterReadingPaymentItem.bill_ten))])]),_c('tr',[_c('td',[_c('base-input',{attrs:{"disable":_vm.loading,"input-classes":"form-control-alternative","type":"number"},on:{"wheel":function($event){$event.preventDefault();},"change":function($event){return _vm.updatePaymentItemDebounced(
                'bill_twenty',
                _vm.cashRegisterReadingPaymentItem.bill_twenty
              )}},model:{value:(_vm.cashRegisterReadingPaymentItem.bill_twenty),callback:function ($$v) {_vm.$set(_vm.cashRegisterReadingPaymentItem, "bill_twenty", $$v)},expression:"cashRegisterReadingPaymentItem.bill_twenty"}})],1),_c('td',[_vm._v("20")]),_c('td',[_vm._v(_vm._s(20 * _vm.cashRegisterReadingPaymentItem.bill_twenty))])]),_c('tr',[_c('td',[_c('base-input',{attrs:{"disable":_vm.loading,"input-classes":"form-control-alternative","type":"number"},on:{"wheel":function($event){$event.preventDefault();},"change":function($event){return _vm.updatePaymentItemDebounced(
                'bill_fifty',
                _vm.cashRegisterReadingPaymentItem.bill_fifty
              )}},model:{value:(_vm.cashRegisterReadingPaymentItem.bill_fifty),callback:function ($$v) {_vm.$set(_vm.cashRegisterReadingPaymentItem, "bill_fifty", $$v)},expression:"cashRegisterReadingPaymentItem.bill_fifty"}})],1),_c('td',[_vm._v("50")]),_c('td',[_vm._v(_vm._s(50 * _vm.cashRegisterReadingPaymentItem.bill_fifty))])]),_c('tr',[_c('td',[_c('base-input',{attrs:{"disable":_vm.loading,"input-classes":"form-control-alternative","type":"number"},on:{"wheel":function($event){$event.preventDefault();},"change":function($event){return _vm.updatePaymentItemDebounced(
                'bill_hundred',
                _vm.cashRegisterReadingPaymentItem.bill_hundred
              )}},model:{value:(_vm.cashRegisterReadingPaymentItem.bill_hundred),callback:function ($$v) {_vm.$set(_vm.cashRegisterReadingPaymentItem, "bill_hundred", $$v)},expression:"cashRegisterReadingPaymentItem.bill_hundred"}})],1),_c('td',[_vm._v("100")]),_c('td',[_vm._v(_vm._s(100 * _vm.cashRegisterReadingPaymentItem.bill_hundred))])]),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t("CASH_REGISTER_READINGS.COINS")))]),_c('td',{attrs:{"colspan":"2"}},[_c('div',{staticClass:"col"},[_c('base-input',{on:{"wheel":function($event){$event.preventDefault();},"change":function($event){return _vm.updatePaymentItemDebounced(
                  'coins',
                  _vm.cashRegisterReadingPaymentItem.coins
                )}},model:{value:(_vm.cashRegisterReadingPaymentItem.coins),callback:function ($$v) {_vm.$set(_vm.cashRegisterReadingPaymentItem, "coins", $$v)},expression:"cashRegisterReadingPaymentItem.coins"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.coins}})],1)])]),_c('tr',[_c('td',{staticClass:"text-end font-weight-bold",attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s(_vm.$t("CASH_REGISTER_READINGS.TOTAL_AMOUNT_ACCOUNT"))+" ")]),_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$formatCurrency(_vm.totalCash))+" ")])])])]),_c('div',{staticClass:"col-12 text-center mb-2 mt-5"},[_c('h2',[_vm._v(_vm._s(_vm.$t("CASH_REGISTER_READINGS.PAYMENT_METHODS")))])]),_c('table',{staticClass:"table table-bordered mt-4"},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("COMMON.PAYMENT_METHOD")))]),_c('th',[_vm._v(_vm._s(_vm.$t("COMMON.AMOUNT")))])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t("CASH_REGISTER_READINGS.CREDIT_CARD")))]),_c('td',[_c('base-input',{attrs:{"disable":_vm.loading,"input-classes":"form-control-alternative","type":"number"},on:{"wheel":function($event){$event.preventDefault();},"change":function($event){return _vm.updatePaymentItemDebounced(
                'credit_card',
                _vm.cashRegisterReadingPaymentItem.credit_card
              )}},model:{value:(_vm.cashRegisterReadingPaymentItem.credit_card),callback:function ($$v) {_vm.$set(_vm.cashRegisterReadingPaymentItem, "credit_card", $$v)},expression:"cashRegisterReadingPaymentItem.credit_card"}})],1)]),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t("CASH_REGISTER_READINGS.DEBIT_CARD")))]),_c('td',[_c('base-input',{attrs:{"disable":_vm.loading,"input-classes":"form-control-alternative","type":"number"},on:{"wheel":function($event){$event.preventDefault();},"change":function($event){return _vm.updatePaymentItemDebounced(
                'debit_card',
                _vm.cashRegisterReadingPaymentItem.debit_card
              )}},model:{value:(_vm.cashRegisterReadingPaymentItem.debit_card),callback:function ($$v) {_vm.$set(_vm.cashRegisterReadingPaymentItem, "debit_card", $$v)},expression:"cashRegisterReadingPaymentItem.debit_card"}})],1)]),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t("CASH_REGISTER_READINGS.PREPAIED_CARD")))]),_c('td',[_c('base-input',{attrs:{"disable":_vm.loading,"input-classes":"form-control-alternative","type":"number"},on:{"wheel":function($event){$event.preventDefault();},"change":function($event){return _vm.updatePaymentItemDebounced(
                'prepaied_card',
                _vm.cashRegisterReadingPaymentItem.prepaied_card
              )}},model:{value:(_vm.cashRegisterReadingPaymentItem.prepaied_card),callback:function ($$v) {_vm.$set(_vm.cashRegisterReadingPaymentItem, "prepaied_card", $$v)},expression:"cashRegisterReadingPaymentItem.prepaied_card"}})],1)]),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t("CASH_REGISTER_READINGS.OTHER")))]),_c('td',[_c('base-input',{attrs:{"disable":_vm.loading,"input-classes":"form-control-alternative","type":"number"},on:{"wheel":function($event){$event.preventDefault();},"change":function($event){return _vm.updatePaymentItemDebounced('other', _vm.cashRegisterReadingPaymentItem.other)}},model:{value:(_vm.cashRegisterReadingPaymentItem.other),callback:function ($$v) {_vm.$set(_vm.cashRegisterReadingPaymentItem, "other", $$v)},expression:"cashRegisterReadingPaymentItem.other"}})],1)]),_c('tr',[_c('td',{staticClass:"text-end font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("CASH_REGISTER_READINGS.TOTAL_PAYMENT_METHODS"))+" ")]),_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$formatCurrency(_vm.totalPayments))+" ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }